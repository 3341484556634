<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="youHuiQuanMC" label="优惠券名称" />
            <x-detail-item prop="faFangZongLiang" label="发放总量" />
            <x-detail-item prop="shengYuSL" label="剩余数量" />
            <x-detail-item prop="youHuiMenJian" label="优惠门槛" />
            <x-detail-item prop="youHuiJinE" label="优惠金额" />
            <x-detail-item prop="youXiaoQiShiSJ" label="有效起始时间" />
            <x-detail-item prop="youXiaoJieShuSJ" label="有效结束时间" />
            <x-detail-item prop="youHuiQuanZT" label="优惠券状态" >
                <x-dict-show  :code="entity.youHuiQuanZT" dictType="T_YHQ_ZT" />
            </x-detail-item>
            <x-detail-item prop="chuangJianSJ" label="创建时间" />
<!--            <x-detail-item label="生效/失效">-->
<!--                <x-dict-show :code="entity.effect" dictType="T_EFFECTIVE"/>-->
<!--            </x-detail-item>-->
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/HuiYuanYouHuiQuan";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                youHuiQuanMC: "", // 优惠券名称
                faFangZongLiang: "", // 发放总量
                shengYuSL: "", // 剩余数量
                youHuiMenJian: "", // 优惠门槛
                youHuiJinE: "", // 优惠金额
                youXiaoQiShiSJ: "", // 有效起始时间
                youXiaoJieShuSJ: "", // 有效结束时间
                youHuiQuanZT: "", // 优惠券状态
                chuangJianSJ: "", // 创建时间
                effect: "", // 生效/失效
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>