<template>
    <div >
        <div >
            <el-dialog title="选择投放会员" :visible.sync="xzhyVisible" width="80%" >
                <!-- 查询区 -->
                <div class="x-page-search">
                    <x-search-item label="姓名" >
                        <el-input v-model="search.xingMing" size="small" />
                    </x-search-item>
                    <x-search-item label="会员类型" >
                        <x-selector-one  v-model="search.huiYuanLeiXing" dictType="T_HUI_YUAN_LX"/>
                    </x-search-item>
                    <div class="x-search-item">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                        <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                    </div>
                </div>
                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="40" fixed/>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column width="180" prop="kaHao" label="卡号" />
                    <el-table-column width="180" prop="xingMing" label="姓名" />
                    <el-table-column width="180" prop="shouJiHao" label="手机号" />
                    <el-table-column width="180" prop="huiYuanLeiXing" label="会员类型" >
                        <x-dict-show slot-scope="{row}" :code="row.huiYuanLeiXing" dictType="T_HUI_YUAN_LX" />
                    </el-table-column>
                    <el-table-column width="180" prop="plusZheKou" label="plus折扣" />
                    <el-table-column width="180" prop="huiYuanDJ" label="会员等级" />
<!--                    <el-table-column width="180" prop="suoShuMenDian" label="所属门店" />-->
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>

                <el-button type="primary" size="mini" round slot="reference">投放</el-button>
                <div slot="footer" class="dialog-footer" align="center">
                    <el-button @click="xzhyVisible = false">取 消</el-button>
                    <label style="margin-right: 100px;margin-left: 100px"></label>
                    <el-popconfirm title="确认投放吗？" @confirm="handletfBatch">
                        <el-button type="primary" slot="reference">投放</el-button>
                    </el-popconfirm>
                </div>
            </el-dialog>

        </div>

    </div>
</template>


<script>
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import * as yhqservice from "@/service/hygl/HuiYuanYouHuiQuan";
    import * as menDianXXservice from "@/service/mdgl/MenDianXX";
    import XTableBase from "@/components/x/XTableBase";

    export default {
        name:"YhqTouFang",
        mixins: [XTableBase],
        data() {

            this.refreshService = hyxxservice.list;
            this.service = hyxxservice;
            return {
                xzhyVisible:false,
                yhqid:"",
                search:{
                    kaHao: '',
                    xingMing: '',
                    shouJiHao: '',
                    huiYuanLeiXing:'',
                    kaZhuangTai: '',
                    kaiKaSJBegin: '',
                    kaiKaSJEnd: '',
                    suoShuMenDian: '',
                },
            };
        },
        methods:{
            xzhyShow(id){
                this.xzhyVisible=true;
                this.yhqid=id;
            },
            handletfBatch(){
                let ids= this.getIds();
                console.log("这是会员ids:",ids)
                console.log("这是优惠券id:",this.yhqid)
                let data={
                    hyids:ids,
                    id:this.yhqid
                }
                yhqservice.touFang(data).then(res=>{
                    if (res.code===200){
                        this.$message.success("优惠券发放成功！")
                        this.xzhyVisible=false;
                        this.$emit('changeTfVisible', false)
                    }
                })
            }

        }
    };
</script>

<style lang="scss" scoped>

</style>