<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="优惠券名称">
                <el-input v-model="search.youHuiQuanMC" size="small"/>
            </x-search-item>
            <x-search-item label="优惠券状态">
                <x-selector-one v-model="search.youHuiQuanZT" dictType="T_YHQ_ZT" size="small"/>
            </x-search-item>
            <x-search-item label="创建时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.chuangJianSJBegin" size="small" type="datetime"/>
                <el-date-picker v-model="search.chuangJianSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <!--            <x-search-item label="状态" >-->
            <!--                <x-selector-one v-model="search.effect" size="small" dictType="T_EFFECTIVE"/>-->
            <!--            </x-search-item>-->
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="youHuiQuanMC" label="优惠券名称"/>
            <el-table-column width="180" prop="faFangZongLiang" label="发放总量"/>
            <el-table-column width="180" prop="shengYuSL" label="剩余数量"/>
            <el-table-column width="180" prop="youHuiMenJian" label="优惠门槛"/>
            <el-table-column width="180" prop="youHuiJinE" label="优惠金额"/>
            <el-table-column width="180" prop="youXiaoQiShiSJ" label="有效起始时间"/>
            <el-table-column width="180" prop="youXiaoJieShuSJ" label="有效结束时间"/>
            <el-table-column width="180" prop="youHuiQuanZT" label="优惠券状态">
                <x-dict-show slot-scope="{row}" :code="row.youHuiQuanZT" dictType="T_YHQ_ZT"/>
            </el-table-column>
            <el-table-column width="180" prop="chuangJianSJ" label="创建时间"/>
            <!--            <el-table-column width="180" label="生效状态">-->
            <!--                <x-dict-show slot-scope="{row}" :code="row.effect" dictType="T_EFFECTIVE" />-->
            <!--            </el-table-column>-->
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-button type="primary" size="mini" round @click="showTfjl(row.id)">投放记录</el-button>
                    <el-button v-show="row.youHuiQuanZT===yhqZT.JXZ.key && row.shengYuSL>0" size="mini" type="success" round
                               @click="showTfDialog(row.id)">投放
                    </el-button>
                    <!--                    <el-popconfirm v-show="row.effect != 'true'" title="请确认是否将这条数据的状态置为生效？" @confirm="handleEffective(row.id)">-->
                    <!--                        <el-button type="success" size="mini" round slot="reference">生效</el-button>-->
                    <!--                    </el-popconfirm>-->
                    <!--                    <el-popconfirm v-show="row.effect == 'true'" title="请确认是否将这条数据的状态置为失效？" @confirm="handleIneffective(row.id)">-->
                    <!--                        <el-button type="danger" size="mini" round slot="reference">失效</el-button>-->
                    <!--                    </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
        <yhq-tou-fang ref="yhqTouFang" @changeTfVisible="changeTfShow" />
        <tou-fang-ji-lu ref="touFangJiLu"/>

        <!--        投放-->
        <el-dialog title="选择投放方式" :visible.sync="tfVisible" width="40%">
            <div align="center">
                <el-popconfirm title="确认对所有会员投放吗？" @confirm="allHyTf">
                    <el-button type="primary" slot="reference" style="height: 50px">所有会员投放</el-button>
                </el-popconfirm>
                <!--                <el-button type="primary"  @click="allHyTf" style="height: 50px">所有会员投放</el-button>-->
                <label style="margin-left: 40px;margin-right: 40px"></label>
                <el-button type="primary" @click="xzHyShow" style="height: 50px">选择会员投放</el-button>
            </div>

        </el-dialog>

    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanYouHuiQuan";
    import * as hyxxservice from "@/service/hygl/HuiYuanXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/yhq/HuiYuanYouHuiQuanEdit";
    import Detail from "@/view/hygl/yhq/HuiYuanYouHuiQuanDetail";
    import YhqTouFang from "@/view/hygl/yhq/YhqTouFang";
    import TouFangJiLu from "@/view/hygl/yhq/TouFangJiLu";
    import {DICT_TYPE_QHY_ZT} from "@/util/constant";

    export default {
        name: "HuiYuanYouHuiQuanList",
        mixins: [XTableBase],
        components: {Edit, Detail, YhqTouFang, TouFangJiLu},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    youHuiQuanMC: '',
                    youHuiQuanZT: '',
                    chuangJianSJBegin: '',
                    chuangJianSJEnd: '',
                    effect: '',
                },
                tfVisible: false,
                yhqid: "",
                allhyids: [],
                yhqZT: DICT_TYPE_QHY_ZT,
            }
        },
        methods: {
            showTfDialog(id) {
                this.tfVisible = true;
                this.yhqid = id;
                console.log(this.yhqid)
            },
            allHyTf() {
                hyxxservice.all().then(res => {
                    this.allhyids = res.data.map(item => item.id)
                    console.log("这是所有会员ids:", this.allhyids)
                    console.log("这是所有会员:", res.data)
                    let data = {
                        hyids: this.allhyids,
                        id: this.yhqid
                    };
                    service.touFang(data).then(res => {
                        if (res.code === 200) {
                            this.$message.success("优惠券发放成功！")
                        }
                    })
                });

            },
            xzHyShow() {
                this.$refs.yhqTouFang.xzhyShow(this.yhqid);
            },
            showTfjl(id) {
                this.$refs.touFangJiLu.tfjlShow(id);
            },
            changeTfShow(changeShow){
                this.tfVisible = changeShow
                this.refresh()
            }
        }
    }
</script>

<style scoped>
</style>
