<template>
    <div >
        <div >
            <el-dialog title="优惠券投放记录" :visible.sync="tfjlVisible" width="80%" >
                <!-- 查询区 -->

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                          @selection-change="handleSelectionChange">
<!--                    <el-table-column type="selection" width="40" fixed/>-->
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <!--            <el-table-column width="180" prop="youHuiQuanID" label="优惠券ID" />-->
                    <!--            <el-table-column width="180" prop="huiYuanID" label="会员ID" />-->
                    <el-table-column width="180" prop="youHuiQuanMC" label="优惠券名称" />
                    <el-table-column width="80" prop="youHuiMenJian" label="优惠门槛" />
                    <el-table-column width="80" prop="youHuiJinE" label="优惠金额" />
                    <el-table-column width="100" prop="huiYuanMC" label="会员名称" />
                    <el-table-column width="180" prop="youXiaoQiShiSJ" label="有效起始时间" />
                    <el-table-column width="180" prop="youXiaoJieShuSJ" label="有效结束时间" />
                    <el-table-column width="80" prop="youHuiQuanZT" label="优惠券状态" >
                        <x-dict-show slot-scope="{row}" :code="row.youHuiQuanZT" dictType="T_YHQ_ZT"/>
                    </el-table-column>
                    <el-table-column width="180" prop="huiYuanShouJiHao" label="会员手机号" />
                    <el-table-column width="180" prop="faFangSJ" label="发放时间" />
                    <el-table-column width="80" prop="shiYongZT" label="使用状态" >
                        <x-dict-show slot-scope="{row}" :code="row.shiYongZT" dictType="T_YHQ_SY_ZT"/>
                    </el-table-column>
                    <el-table-column width="180" prop="shiYongSJ" label="使用时间" />
                    <el-table-column width="180" prop="shiYongMenDian" label="使用门店" />
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>

            </el-dialog>

        </div>

    </div>
</template>


<script>
    import * as service from "@/service/hygl/YouHuiQuanFaFangJiLu";
    import XTableBase from "@/components/x/XTableBase";

    export default {
        name:"TouFangJiLu",
        mixins: [XTableBase],
        data() {
            this.searchNoClear = {current: 1, size: 10};
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            this.service = service;
            return {
                tfjlVisible:false,
                yhqid:"",
                search:{
                    youHuiQuanMC: '',
                    youHuiQuanZT: '',
                    huiYuanMC: '',
                    huiYuanShouJiHao: '',
                    youHuiQuanID: '',
                    shiYongZT: '',
                },
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                },
            };
        },
        methods:{
            tfjlShow(id){
                this.search.youHuiQuanID=id;
                this.tfjlVisible=true;
                this.yhqid=id;
                this.refresh()
                console.log(this.search.youHuiQuanID)
                console.log(this.table.data)

            },
            refresh(){
                service.list(Object.assign(this.search, this.searchNoClear)).then(res=>{
                    console.log(res)
                    this.table.data=res.data.records
                    this.table.total = res.data.total || 0;
                })

            },

        }
    };
</script>

<style lang="scss" scoped>

</style>