<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="750px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="优惠券名称" prop="youHuiQuanMC" class="line" style="border: none; width: 300px;">
                <el-input v-model="form.youHuiQuanMC" size="small" maxlength="36" show-word-limit style="width:300px;"/>
            </el-form-item>
            <el-form-item label="发放总量" prop="faFangZongLiang" class="line" style="border: none; width: 300px;">
                <el-input v-model="form.faFangZongLiang" size="small" maxlength="" show-word-limit
                          style="width:300px;"/>
            </el-form-item>
            <el-form-item v-show="form.id !== null" label="剩余数量" prop="shengYuSL" class="line"
                          style="border: none; width: 300px;">
                <el-input v-model="form.shengYuSL" size="small" maxlength="" disabled show-word-limit
                          style="width:300px;"/>
            </el-form-item>
            <el-form-item label="优惠门槛" prop="youHuiMenJian" class="line" style="border: none; width: 300px;">
                <el-input v-if="this.form.id!==null" v-model="form.youHuiMenJian" size="small" disabled maxlength="8"
                          show-word-limit style="width:300px;"/>
                <el-input v-else v-model="form.youHuiMenJian" size="small" maxlength="8" show-word-limit
                          style="width:300px;"/>

            </el-form-item>
            <el-form-item label="优惠金额" prop="youHuiJinE" class="line" style="border: none; width: 300px;">
                <el-input v-if="this.form.id!==null" v-model="form.youHuiJinE" size="small" disabled maxlength="8"
                          show-word-limit style="width:300px;"/>
                <el-input v-else v-model="form.youHuiJinE" size="small" maxlength="8" show-word-limit
                          style="width:300px;"/>
                <lable>满【{{this.form.youHuiMenJian}}】元减【{{this.form.youHuiJinE}}】元</lable>
            </el-form-item>
            <el-form-item label="有效起始时间" prop="youXiaoQiShiSJ" class="w50">
                <el-date-picker v-if="this.form.id!==null" v-model="form.youXiaoQiShiSJ" size="small" disabled
                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
                <el-date-picker v-else v-model="form.youXiaoQiShiSJ" size="small" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="有效结束时间" prop="youXiaoJieShuSJ" class="w50">
                <el-date-picker v-if="this.form.id!==null" v-model="form.youXiaoJieShuSJ" size="small" disabled
                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
                <el-date-picker v-else v-model="form.youXiaoJieShuSJ" size="small" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <!--            <el-form-item label="优惠券状态" prop="youHuiQuanZT" >-->
            <!--&lt;!&ndash;                <el-input v-model="form.youHuiQuanZT" size="small" maxlength="36" show-word-limit/>&ndash;&gt;-->
            <!--                <x-selector-one v-model="form.biaoQianLX" dictType="T_YHQ_ZT"/>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="创建时间" prop="chuangJianSJ">-->
            <!--                <el-date-picker v-model="form.chuangJianSJ" size="small" type="datetime" disabled value-format="yyyy-MM-dd HH:mm:ss"/>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="生效/失效" prop="effect" >-->
            <!--                <x-switch v-model="form.effect" dictType="T_EFFECTIVE" />-->
            <!--            </el-form-item>-->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="thisSave" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/HuiYuanYouHuiQuan";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required, money, digits} = ruleBuilder;
            this.rules = {
                youHuiQuanMC: [required()], // 优惠券名称
                faFangZongLiang: [required(), digits()], // 发放总量
                shengYuSL: [required(), digits()], // 剩余数量
                youHuiMenJian: [required(), money()], // 优惠门槛
                youHuiJinE: [required(), money()], // 优惠金额
                youXiaoQiShiSJ: [required()], // 有效起始时间
                youXiaoJieShuSJ: [required()], // 有效结束时间
                youHuiQuanZT: [], // 优惠券状态
                chuangJianSJ: [], // 创建时间
                effect: [], // 生效/失效
            }
            this.titlePrefix = '会员优惠券';
            this.defaultForm = {
                id: null,
                youHuiQuanMC: "", // 优惠券名称
                faFangZongLiang: "", // 发放总量
                shengYuSL: "", // 剩余数量
                youHuiMenJian: "", // 优惠门槛
                youHuiJinE: "", // 优惠金额
                youXiaoQiShiSJ: "", // 有效起始时间
                youXiaoJieShuSJ: "", // 有效结束时间
                youHuiQuanZT: "", // 优惠券状态
                chuangJianSJ: "", // 创建时间
                effect: "", // 生效/失效
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        methods: {
            thisSave() {
                if (this.form.id === null) {
                    this.form.shengYuSL = this.form.faFangZongLiang;
                }
                if (this.form.youXiaoQiShiSJ > this.form.youXiaoJieShuSJ) {
                    this.$message.warning("有效期起始时间不可以超过结束时间")
                    return;
                }
                this.save()
            }
        }
    }
</script>

<style scoped>

</style>