import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-you-hui-quan/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-you-hui-quan/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-you-hui-quan/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/delete-batch',
        method: 'post',
        data
    })
}

export function touFang(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/tou-fang',
        method: 'post',
        data
    })
}

//有效无效
export function effect(data) {
    return request({
        url: '/hui-yuan-you-hui-quan/effect',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'hui-yuan-you-hui-quan-list',
    component: () => import('@/view/hygl/yhq/HuiYuanYouHuiQuanList'),
    name: 'HuiYuanYouHuiQuanList',
    meta: {title: '会员优惠券', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"会员优惠券基础列表","methodUrl":"/hui-yuan-you-hui-quan/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员优惠券添加保存","methodUrl":"/hui-yuan-you-hui-quan/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员优惠券获取编辑数据","methodUrl":"/hui-yuan-you-hui-quan/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员优惠券获取详情数据","methodUrl":"/hui-yuan-you-hui-quan/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员优惠券编辑保存","methodUrl":"/hui-yuan-you-hui-quan/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员优惠券删除","methodUrl":"/hui-yuan-you-hui-quan/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员优惠券有效/无效","methodUrl":"/hui-yuan-you-hui-quan/effect","operateType":"edit","sort":10,"functionClassification":"0","_key":"10"},
{"name":"会员优惠券批量删除","methodUrl":"/hui-yuan-you-hui-quan/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
